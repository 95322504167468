<template>
    <error404 v-if="showPage404"></error404>
    <div v-else>
        <nav style="background-color: #37bc9b">
            <div style="height: 3.5rem" class="p-2">
                <img style="height: 2.5rem" alt="logo" :src="logo">
            </div>
        </nav>
        <loadScreenComponent :isLoading="isLoading"/>
        <div class="main-container mt-2">
            <div class="card">
                <div class="card-body row gutters pb-1">
                    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-12">
                        <div data-cy="situation_lot" class="form-group">
                            <label>{{ t('PUBLIC_AREA.CITY') }}: </label>
                            <Select2
                                :settings="{width: '100%'}"
                                :options="optionsCity"
                                @select="setOptionsCity"
                                v-model="filter.city_id"
                                placeholder="Cidades"
                                id="city_filter_index" name="city_filter_index"/>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-12">
                        <div data-cy="situation_lot" class="form-group">
                            <label>{{ t('PUBLIC_AREA.ALLOTMENT') }}: </label>
                            <Select2
                                placeholder="Loteamentos"
                                @select="setOptionsAllotment"
                                :options="optionsAllotment"
                                v-model="filter.allotment_id"
                                :settings="{ width: '100%' }"
                                id="allotment_filter" name="allotment_filter"/>
                        </div>
                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-3 col-sm-3 col-5">
                        <div data-cy="situation_lot" class="form-group">
                            <label>{{ t('PUBLIC_AREA.BLOCK') }}: </label>
                            <Select2
                                placeholder="Quadras"
                                :options="optionsBlock"
                                @select="setOptionsBlock"
                                v-model="filter.block"
                                :settings="{ width: '100%' }"
                                id="block_filter" name="block_filter"/>
                        </div>
                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-3 col-sm-3 col-4">
                        <div data-cy="situation_lot" class="form-group">
                            <label>{{ t('PUBLIC_AREA.LOT') }}: </label>
                            <Select2
                                placeholder="Lotes"
                                @select="setOptionsLot"
                                :options="optionsLot"
                                v-model="filter.name"
                                :disabled="!filter.block"
                                :settings="{ width: '100%' }"
                                id="lot_filter" name="lot_filter"/>
                        </div>
                    </div>
                    <div class="col-3 mt-4" @click="clearFilters" v-if="widthMd(576, '<')">
                        <div class="btn btn-primary">Limpar</div>
                    </div>
                    <div
                        class="col-xl-2 col-lg-2 col-md-5 col-sm-5 col-12 w-100 d-flex flex-column
                   pl-4 pr-4  pr-xl-0 pr-lg-0 pr-sm-0 pr-md-0
                   mb-4 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-0" v-if="widthMd(576, '>')">
                        <label class="form-label mt-1 mb-3 align-self-center"> {{ t('PUBLIC_AREA.METRO2') }}</label>
                        <Slider :min="total_areaMinMax.min" :max="total_areaMinMax.max" v-model="total_area"
                                @change="changeMinMaxTotalArea"
                                :format="toCurrencyTotalArea"
                                :id="'totalArea'"></Slider>
                    </div>
                    <div class="col-1" v-if="widthMd(576, '>')"><i></i></div>
                    <div class="col-xl-2 col-lg-2 col-md-5 col-sm-5 col-12 d-flex flex-column
               pl-xl-0 pr-xl-0 pl-lg-0 pr-lg-0 pl-md-0 pr-md-0 pl-sm-0 pr-sm-0 pl-4 pr-4" v-if="widthMd(576, '>')">
                        <label class="form-label mt-1 mb-3 align-self-center">{{ t('PUBLIC_AREA.VALUE_R$') }}</label>
                        <Slider :min="priceMinMax.min" @change="changeMinMaxPrice"
                                :format="toCurrencyTotalArea"
                                :max="priceMinMax.max" v-model="price" :id="'price'"></Slider>
                    </div>

                    <div class="col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 justify-content-end d-flex flex-row w-100 align-items-center
                  mt-4 mt-xl-0 mt-lg-0 mt-md-4 mt-sm-4" @click="clearFilters" v-if="widthMd(576, '>')">
                        <div class="btn  btn-primary"> Limpar</div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-hover table-bordered">
                            <thead>
                            <tr>
                                <th class="text-center">Cidade</th>
                                <th class="text-center">Loteamento</th>
                                <th class="text-center">Quadra</th>
                                <th class="text-center">Lote</th>
                                <th class="text-center">Frente</th>
                                <th class="text-center">Lateral</th>
                                <th class="text-center" @click="orderByMetro" style="cursor: pointer">Metragem
                                    <i class="icon-arrow-up" v-if="orderMetro === 'ASC'"></i>
                                    <i class="icon-arrow-down" v-if="orderMetro === 'DESC'"></i>
                                </th>
                                <th class="text-center" @click="orderByPrice" style="cursor: pointer">Valor
                                    <i class="icon-arrow-up" v-if="orderPrice === 'ASC'"></i>
                                    <i class="icon-arrow-down" v-if="orderPrice === 'DESC'"></i>
                                </th>
                                <th class="text-center">Empresa</th>
                                <th class="text-center">Situação</th>
                                <th class="text-center">
                                    <div class="row gutters">
                                        <p class="col-4"></p>
                                        <p class="align-self-center col-4">Ações</p>
                                        <p class="col-1"></p>

                                        <button class="btn btn-primary col-2" style="min-width: 40px"
                                                @click="exporPdf()"><i
                                            class="material-icons-outlined">picture_as_pdf</i></button>
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, key) in data.data" :key="key">
                                <td class="text-center">{{ item?.allotments?.cities?.name }}</td>
                                <td class="text-center">{{ item?.allotments?.name }}</td>
                                <td class="text-center">{{ item?.block }}</td>
                                <td class="text-center">{{ item?.name }}</td>
                                <td class="text-center">{{ item?.lot.front_size }}</td>
                                <td class="text-center">{{ item?.lot.right_size }}</td>
                                <td class="text-center">{{ showToCurrencyTotalArea(item) }}</td>
                                <td class="text-center">{{ toCurrency(item) }}</td>
                                <td class="text-center">{{ item?.company_name }}</td>
                                <td class="text-center">
                     <span class="btn w-100 rounded-pill "
                           :style="[{'background-color' : item?.properties_status?.backgroud_collor},
                                                {'color': item?.properties_status?.text_collor}, {'cursor': 'grab'}]"
                     >{{ item?.properties_status?.name }}</span>
                                </td>
                                <td class="pr-0">
                                    <div>
                                        <div
                                            v-if="item?.allotments?.public_area_config?.action_show_maps_on_public_area
                           && item.allotments.url_site && !item.allotments.url_site.includes('indisponivel')"
                                            class="btn btn-primary ml-2"
                                            :class="widthMd(1200, '<') ? 'mb-2 mt-1' : ''"
                                            @click="sendUrl(item)"
                                            title="URL Mapa">
                                            <i class="icon-map font-15"/>
                                        </div>
                                        <div
                                            v-if="item?.properties_status?.type === 0 &&
                           item?.allotments?.public_area_config?.action_calculate_financing_on_public_area"
                                            title="Simular"
                                            class="btn btn-primary ml-2"
                                            :class="widthMd(1200, '<') ? 'mb-2 mt-1' : ''"
                                            data-target="#customModalTwo"
                                            @click="setCalculatorModal(item)"
                                            data-toggle="modal">
                                            <i class="icon-dollar-sign font-15"/>
                                        </div>
                                        <div
                                            v-if="item?.allotments?.public_area_config?.action_view_lot_data_on_public_area"
                                            title="Informações"
                                            class="btn btn-primary ml-2"
                                            :class="widthMd(1200, '<') ? 'mb-2 mt-1' : ''"
                                            data-target="#customModalTwo"
                                            @click="setViewModal({...item})"
                                            data-toggle="modal">
                                            <i class="icon-eye1 font-15"/>
                                        </div>
                                        <div
                                            v-if="item?.properties_status?.type === 0 &&
                           item?.allotments?.public_area_config?.action_reserve_lot_on_public_area"
                                            title="Reservar"
                                            class="btn btn-primary ml-2"
                                            :class="widthMd(1200, '<') ? 'mb-2 mt-1' : ''"
                                            data-target="#customModalTwo"
                                            @click="setReserveModal(item)"
                                            data-toggle="modal">
                                            <i class="icon-lock-open font-15"/>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <td colspan="11" v-if="!data?.data?.length">
                                <div class="text-center p-3">
                                    <h5>{{ t('PUBLIC_AREA.NOT_FOUND') }}</h5>
                                </div>
                            </td>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <modal :titleModal="modalTitle" :modal-extra-large="whichModal === 2" :modal-xl="whichModal !== 2"
               @close="closeModal">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <div v-if="whichModal === 1">
                <div class="modal-body">
                    <div class="row gutters">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="form-group">
                                <label>{{ t('LOTS.ALLOTMENT') }}: </label>
                                <label class="ml-1 color-grey">{{ propertySelected?.allotments?.name }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div class="form-group">
                                <label>{{ t('LOTS.BLOCK') }}: </label>
                                <label class="ml-1 color-grey">{{ propertySelected?.block }}</label>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div class="form-group">
                                <label>{{ t('LOTS.LOT') }}: </label>
                                <label class="ml-1 color-grey">{{ propertySelected?.name }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div class="form-group">
                                <label for="email" class="required">{{ t('GENERAL.EMAIL') }}: </label>
                                <input type="text" id="email" name="email" v-model="user.email"
                                       data-cy="reserve_modal_validation_email" class="form-control"
                                       @keyup="clearMail()" placeholder="nome@email.com">
                                <div class="validation" id="invalidEmail" style="display: none">{{ msgEmail }}</div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div class="form-group">
                                <label for="password" class="required">{{ t('GENERAL.PASSWORD') }}: </label>
                                <input type="password" id="password" v-model="user.password" name="password"
                                       class="form-control" placeholder="Senha"
                                       @keyup="clearPassword()"/>
                                <div class="validation" id="invalidpassword" style="display: none"
                                     data-cy="reserve_modal_validation_password">{{ t('GENERAL.MSG.REQUIRED_FIELD') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex">
                    <button class="btn btn-primary mr-auto" @click.stop.prevent="openRecoverPasswordPage">
                        {{ t('GENERAL.RECOVER_PASSWORD') }}
                    </button>
                    <button data-cy="public_lot_reserve_close" class="btn btn-secondary" data-dismiss="modal"
                            type="button">
                        {{ t('ACTIONS.CLOSE') }}
                    </button>
                    <button data-cy="public_lot_reserve_confirm" class="btn btn-primary" @click.stop.prevent="create">
                        {{ t('ACTIONS.CONFIRM') }}
                    </button>
                </div>
            </div>

            <iframe style="height: 500px;" :src="srcIframe" :allow="`clipboard-read ${hostSimulator}; clipboard-write ${hostSimulator}`"
                    v-if="whichModal == 2 && srcIframe"></iframe>

            <div class="modal-footer right-content" v-if="whichModal == 2">
                <button data-cy="lot_index_simulate_close" class="btn btn-secondary" data-dismiss="modal" type="button"
                        @click="closeIframe()">
                    {{ t('ACTIONS.CLOSE') }}
                </button>
            </div>
            <view-lot-component v-if="whichModal === 3" :properties-index="propertySelected"
                                :properties-status-index="propertiesStatus"
                                :lots-index="propertySelected.lot"></view-lot-component>
        </modal>


        <div class="row w-100">
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 d-flex justify-content-end">
                <div>
                    <pagination-component v-if="data && data.data && data.data[0]" :items="data"
                                          @changePage="index($event), this.page=$event"></pagination-component>
                </div>

            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 row justify-content-end">
                <div class="form-group w-50">
                    <label> Qtd. de items por página:</label>
                    <Select2
                        placeholder="Paginação"
                        :options="optionsPaginate"
                        @select="setPerPage"
                        v-model="per_page"
                        :settings="{ width: '100%' }"
                        id="paginate_filter" name="paginate_filter"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapState} from 'vuex';
import {useI18n} from 'vue-i18n';
import Slider from '@vueform/slider';
import Public from '@/services/Public';
import modal from '../../components/modal';
import {useToast} from 'vue-toastification';
import Select2 from 'vue3-select2-component';
import Validate from '@/components/validate.vue';
import PublicArea from '../../services/publicArea';
import viewLotComponent from '../lot/viewLotComponent';
import error404 from '../../components/layouts/error404';
import BuildSimulatorUrl from '@/Helpers/BuildSimulatorUrl';
import PaginationComponent from '@/components/layouts/PaginationComponent';
import loadScreenComponent from '../../components/layouts/loadScreenComponent';

export default {
    name: 'indexPublicAreaComponent',

    mixins: [Validate, BuildSimulatorUrl],

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast};
    },

    components: {
        modal,
        Slider,
        Select2,
        error404,
        viewLotComponent,
        loadScreenComponent,
        PaginationComponent,
    },

    mounted() {
        this.index();
        this.minMax();
    },

    data() {
        return {
            propertiesStatus: [],
            propertiesType: [],
            orderMetro: null,
            optionsPaginate: [{id: 'default', text: 'Paginação'}, {id: 20, text: '20'}, {id: 40, text: '40'}, {
                id: 60,
                text: '60',
            }, {id: 80, text: '80'}, {id: 100, text: '100'},
                {id: 120, text: '120'}, {id: 140, text: '140'}, {id: 160, text: '160'}, {id: 180, text: '180'}],
            titles: ['Cidade', 'Loteamento', 'Quadra', 'Lote', 'Frente', 'Lateral', 'Metragem', 'Valor', 'empresa', 'Situação'],
            modalTitle: null,
            orderPrice: null,
            per_page: null,
            whichModal: null,
            filter: {},
            logo: null,
            srcIframe: null,
            showPage404: false,
            id: this.$route.params.groupid,
            optionsCity: [],
            optionsAllotment: [],
            optionsBlock: [],
            page: 1,
            user: {},
            optionsLot: [],
            propertySelected: {},
            price: [1, 99],
            total_areaMinMax: {min: 1, max: 99},
            priceMinMax: {min: 1, max: 99},
            total_area: [1, 99],
            data: [],
        };
    },

    computed: {
        ...mapState({
            isLoading: 'isLoading',
        }),
        hostSimulator() {
            return process.env.VUE_APP_SIMULATOR_HOST;
        }
    },

    methods: {
        clearPassword() {
            if (this.user.password) {
                this.user.password = this.user.password.trim();
            }
            this.inputGeneric(this.user.password, 'password');
        },

        openRecoverPasswordPage() {
            window.open('/recuperar-senha', '_blank');
        },

        clearMail() {
            if (this.user.email) {
                this.user.email = this.user.email.trim();
                this.user.email = this.user.email.toLowerCase();
            }
            this.inputEmail(this.user.email);
        },

        widthMd(size = 1025, smaller = '<') {
            if (smaller == '<') {
                return window.innerWidth < size;
            } else {
                return window.innerWidth > size;
            }
        },

        clearFilters() {
            this.filter = {};
            this.page = 1;
            this.index();
            this.minMax();
        },

        minMax() {
            PublicArea.minMaxList(this.id).then(resp => {
                if (resp.data.price.min !== null && resp.data.price.max) {
                    this.priceMinMax = {min: parseInt(resp.data.price.min), max: parseInt(resp.data.price.max)};
                    this.price = [parseInt(resp.data.price.min), parseInt(resp.data.price.max)];
                }

                if (resp.data.total_area.min !== null && resp.data.total_area.max !== null) {
                    this.total_areaMinMax = {
                        min: parseFloat(resp.data.total_area.min),
                        max: parseFloat(resp.data.total_area.max),
                    };
                    this.total_area = [parseFloat(resp.data.total_area.min), parseFloat(resp.data.total_area.max)];
                }
            });
        },

        toCurrency(item) {
            if (item.price === null) return 'Indisponível';

            if (item.allotments?.public_area_config?.price?.show) {
                return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(item.price);
            }

            return '-';
        },

        showToCurrencyTotalArea(item) {
            if (item.total_area === null) return 'Indisponível';

            if (item.allotments?.public_area_config?.total_area?.show) {
                return Intl.NumberFormat('pt-br', {currency: 'BRL'}).format(item.total_area);
            }

            return '-';
        },

        toCurrencyTotalArea(value) {
            if (value === null) return 'Indisponível';
            return Intl.NumberFormat('pt-br', {currency: 'BRL'}).format(value);
        },

        setViewModal(item) {
            this.propertiesStatus = [item.properties_status];
            this.propertiesType = [item.properties_types];

            this.modalTitle = 'GROUP.VIEW_LOT';
            item.allotments.text = item.allotments.name;
            item.properties_status.text = item.properties_status.name;
            item.properties_types.text = item.properties_types.name;
            item.properties_types = [item.properties_types];
            item.properties_status = [item.properties_status];
            this.whichModal = 3;
            this.propertySelected = item;
        },

        async setCalculatorModal(property) {
            this.modalLarge = true;
            this.modalTitle = 'LOTS.MODAL_TITLE.SIMULATE';
            this.whichModal = 2;

            await this.buildSimulatorUrl(property.id).then(url => this.srcIframe = url)
                .catch(err => {
                    this.errorMsg(err);
                    document.getElementById('closeX').click();
                });
        },

        closeModal() {
            if (this.whichModal === 2) this.closeIframe();
        },

        closeIframe() {
            document.getElementById('closeX')?.click();
            this.whichModal = null;
            this.srcIframe = null;
            this.modalLarge = true;
            this.modalTitle = 'LOTS.MODAL_TITLE.SIMULATE';
        },

        create() {
            if (this.checkForm()) {
                this.$store.commit('changeLoading', true);

                const form = {
                    username: this.user.email,
                    password: this.user.password,
                    propertyId: this.propertySelected.id,
                };

                Public.ReserveCreate(this.propertySelected.company_id, form).then(() => {
                    this.index();
                    document.getElementById('closeX').click();
                    this.toast.success(this.t('GROUP.RESERVED_SUCCESS'));
                }).catch(error => this.errorMsg(error))
                    .finally(() => this.$store.commit('changeLoading', false));
            }
        },

        checkForm() {
            this.invalid = [];

            this.emailValidate(this.user.email);
            this.validateGeneric(this.user.password, 'password');

            if (this.invalid.length && this.invalid.length > 0) {
                return false;
            }
            return true;
        },

        setReserveModal(item) {
            this.propertySelected = item;
            this.modalTitle = 'RESERVE.MODAL_TITLE.ADD';
            this.whichModal = 1;
        },

        setOptionsCity({id}) {
            if (id === 'selecione') {
                this.filter = {};
            } else {
                delete this.filter.allotment_id;
                delete this.filter.block;
                delete this.filter.name;
            }

            this.$store.commit('changeLoading', true);
            PublicArea.allotmentsList(this.id, this.filter).then(resp => {
                this.optionsAllotment = resp.data;
                this.optionsAllotment.unshift({id: 'selecione', text: 'Selecionar Loteamento'});
            });
            PublicArea.blockList(this.id, this.filter).then((resp) => {
                this.optionsBlock = resp.data;
                this.optionsBlock.unshift({id: 'selecione', text: 'Selecionar'});
            });
            PublicArea.index(this.id, this.filter, null, this.per_page)
                .then(resp => this.data = resp.data)
                .finally(() => this.$store.commit('changeLoading', false));
        },

        exporPdf() {
            this.$store.commit('changeLoading', true);
            PublicArea.index(this.id, this.filter, null, null, true).then((resp) => {
                var base64Data = resp.data;
                var byteCharacters = atob(base64Data.split(',')[1]);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var blob = new Blob([byteArray], {type: 'application/pdf'});
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = 'arquivo.pdf';
                document.body.appendChild(a);
                a.click();
                setTimeout(function () {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 0);
            }).finally(() => this.$store.commit('changeLoading', false));
        },

        orderByMetro() {
            if (this.orderMetro === null) {
                this.orderMetro = 'ASC';
            } else if (this.orderMetro === 'ASC') {
                this.orderMetro = 'DESC';
            } else if (this.orderMetro === 'DESC') {
                this.orderMetro = null;
            }
            this.$store.commit('changeLoading', true);

            PublicArea.index(this.id, this.filter, null, null, null, this.orderPrice, this.orderMetro)
                .then(resp => this.data = resp.data)
                .finally(() => this.$store.commit('changeLoading', false));
        },

        orderByPrice() {
            if (this.orderPrice === null) {
                this.orderPrice = 'ASC';
            } else if (this.orderPrice === 'ASC') {
                this.orderPrice = 'DESC';
            } else if (this.orderPrice === 'DESC') {
                this.orderPrice = null;
            }
            this.$store.commit('changeLoading', true);
            PublicArea.index(this.id, this.filter, null, null, null, this.orderPrice, this.orderMetro)
                .then(resp => this.data = resp.data)
                .finally(() => this.$store.commit('changeLoading', false));
        },

        changeMinMaxPrice(e) {
            this.$store.commit('changeLoading', true);
            this.filter.price_min = e[0];
            this.filter.price_max = e[1];

            PublicArea.index(this.id, this.filter, null, this.per_page)
                .then(resp => this.data = resp.data)
                .finally(() => this.$store.commit('changeLoading', false));
        },

        changeMinMaxTotalArea(e) {
            this.$store.commit('changeLoading', true);
            this.filter.total_area_min = e[0];
            this.filter.total_area_max = e[1];

            PublicArea.index(this.id, this.filter, null, this.per_page)
                .then(resp => this.data = resp.data)
                .finally(() => this.$store.commit('changeLoading', false));
        },

        setOptionsAllotment({id}) {
            if (id === 'selecione') {
                delete this.filter.allotment_id;
            } else {
                delete this.filter.block;
                delete this.filter.name;
            }

            this.$store.commit('changeLoading', true);
            PublicArea.blockList(this.id, this.filter).then(resp => {
                this.optionsBlock = resp.data;
                this.optionsBlock.unshift({id: 'selecione', text: 'Selecionar'});
            });

            PublicArea.index(this.id, this.filter, null, this.per_page)
                .then(resp => this.data = resp.data)
                .finally(() => this.$store.commit('changeLoading', false));
        },

        setOptionsBlock({id}) {
            if (id === 'selecione') {
                delete this.filter.block;
                delete this.filter.name;
            } else {
                delete this.filter.name;
            }
            this.$store.commit('changeLoading', true);
            PublicArea.lotList(this.id, this.filter).then(resp => {
                this.optionsLot = resp.data;
                this.optionsLot.unshift({id: 'selecione', text: 'Selecionar'});
            });

            PublicArea.index(this.id, this.filter, null, this.per_page).then(resp => this.data = resp.data)
                .then(() => this.$store.commit('changeLoading', false));
        },

        setOptionsLot({id}) {
            if (id === 'selecione') {
                delete this.filter.name;
            }
            this.$store.commit('changeLoading', true);
            PublicArea.index(this.id, this.filter, null, this.per_page)
                .then(resp => this.data = resp.data)
                .then(() => this.$store.commit('changeLoading', false));
        },

        index(page = 1) {
            window.scrollTo(0, 0);
            this.page = page;
            this.$store.commit('changeLoading', true);

            PublicArea.citiesList(this.id).then(resp => {
                this.logo = resp.data.company.find(item => item.logo);
                this.logo = this.logo.logo;
                this.optionsCity = resp.data.cities;
                this.optionsCity.unshift({id: 'selecione', text: 'Selecionar Cidade'});
            });
            PublicArea.allotmentsList(this.id).then(resp => {
                this.optionsAllotment = resp.data;
                this.optionsAllotment.unshift({id: 'selecione', text: 'Selecionar Loteamento'});
            });
            PublicArea.blockList(this.id).then(resp => {
                this.optionsBlock = resp.data;
                this.optionsBlock.unshift({id: 'selecione', text: 'Selecionar'});
            });
            PublicArea.index(this.id, this.filter, this.page, this.per_page, null, this.orderPrice)
                .then(resp => this.data = resp.data).catch(err => {
                if (err.response.status === 404) {
                    this.showPage404 = true;
                }
            }).finally(() => this.$store.commit('changeLoading', false));
        },

        setPerPage() {
            window.scrollTo(0, 0);
            this.$store.commit('changeLoading', true);
            PublicArea.index(this.id, this.filter, null, this.per_page)
                .then(resp => this.data = resp.data)
                .finally(() => this.$store.commit('changeLoading', false));
        },

        sendUrl(item) {
            window.open(item.allotments.url_site, 'blank');
        },
    },
};
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style>
.paddin {
    padding-left: 1rem !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.5rem !important;
}

body.authentication {
    display: block;
    align-items: flex-start;
    justify-content: left;
    background: #eff1f5;
}

.modal-row {
    max-height: 100%;
    overflow-y: auto;
}

.modal-row::-webkit-scrollbar {
    width: 7px;
    border-radius: 10px;
    background: transparent !important;
}

.modal-row::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 10px;
}

.secondary {
    border-radius: 3px;
    color: #ffffff !important;
    background-color: #383737;
}

.first {
    border-radius: 0px;
    background: transparent;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem 0;
    font-size: 1.5rem;
    align-items: center;
}

.info-lot {
    border-radius: 7px;
    padding-bottom: 1px;
    padding-top: 6px;
}

.space-between {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}

.color-grey {
    color: #727272;
}

.font {
    font-size: 0.8rem;
}

.fab {
    position: fixed;
    bottom: 5px;
    right: 10px;
}

.fab button {
    cursor: pointer;
    width: 100%;
    height: 45px;
    border-radius: 20px;
    background-color: #383737;
    border: none;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .4);
    font-size: 0.9rem;
    color: black;

    -webkit-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    transition: .2s ease-out;
}

.fab button.main {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: #0e5e4e !important;
    right: 0;
    bottom: 0;
    z-index: 20;
}

.fab ul {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0;
    padding-right: 5px;
    margin: 0;
    list-style: none;
    z-index: 10;

    -webkit-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    transition: .2s ease-out;
}

.fab ul li {
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin-bottom: -10%;
    opacity: 0;

    -webkit-transition: .3s ease-out;
    -moz-transition: .3s ease-out;
    transition: .3s ease-out;
}

.fab ul li label {
    margin-right: 10px;
    white-space: nowrap;
    display: block;
    margin-top: 10px;
    padding: 5px 8px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
    border-radius: 3px;
    height: 18px;
    font-size: 16px;
    pointer-events: none;
    opacity: 0;

    -webkit-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    transition: .2s ease-out;
}

.fab button.main:active,
.fab button.main:focus {
    outline: none;
    background-color: #000000;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .5);
}

.fab button.main:active + ul,
.fab button.main:focus + ul {
    bottom: 70px;
}

.fab button.main:active + ul li,
.fab button.main:focus + ul li {
    margin-bottom: 8px;
    opacity: 1;
}

.fab button.main:active + ul li:hover label,
.fab button.main:focus + ul li:hover label {
    opacity: 1;
}
</style>
